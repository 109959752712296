import React, { ReactNode, useContext, useEffect, useState } from "react";

import useFetchResource from "hooks/useFetchResource";
import { get as getOrganizationData } from "services/organization";

interface SetupContextType {
  open_setup: boolean;
  setOpenSetup: (setup: boolean) => void;
}

const SetupContext = React.createContext<SetupContextType | undefined>(
  undefined
);

function SetupProvider({ children }: { children: ReactNode }) {
  const [open_setup, setOpenSetup] = useState<boolean>(false);

  const { data } = useFetchResource({
    fn: getOrganizationData,
    key: ["organization"],
  });

  useEffect(() => {
    if (data?.org_onboarding_status === "pending") {
      setOpenSetup(true);
    }
  }, [data?.org_onboarding_status]);

  return (
    <SetupContext.Provider value={{ open_setup, setOpenSetup }}>
      {children}
    </SetupContext.Provider>
  );
}

const useSetup = () => {
  const context = useContext(SetupContext);
  if (!context) {
    throw new Error("useSetup must be used within a SetupProvider");
  }
  return context;
};

export { SetupProvider, useSetup };
