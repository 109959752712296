export const urlConfig = {
  signupURL: "https://signup.organogram.xyz",
  apiUrl: "https://api.people.organogram.xyz/api/v1/",
  aiApiUrl: "https://ai.organogram.xyz/api/v1",
  mySpaceUrl: "https://myspace.organogram.xyz",
  payrollUrl: "https://payroll.organogram.xyz",
  hrUrl: "https://people.organogram.xyz",
  adminUrl: "https://admin.organogram.xyz",
  workspaceTrends: "https://trends.organogram.xyz/api/v1/trends",
};
