import axios from "axios";

import { setSessionExpiredSignal } from "../redux/slices/auth";
import { store } from "../redux/store";
import { AuthService } from "./auth";

const { getToken, updateToken, isLoggedIn, getRefreshToken } = AuthService;

const api = axios.create();

api.interceptors.request.use((config) => {
  if (isLoggedIn()) {
    const config_callback = () => {
      const token = getToken() || getRefreshToken();
      config.headers.authorization = `Bearer ${token}`;
      return Promise.resolve(config);
    };
    return updateToken(config_callback);
  }
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(setSessionExpiredSignal(true));
    }
    return Promise.reject(error);
  }
);

const apiRequest = async (method, url, options = {}) => {
  try {
    const { data, query_string, headers, responseType } = options;
    const requestUrl =
      method === "get" && query_string ? `${url}?${query_string}` : url;

    const config = {
      method,
      url: requestUrl,
      headers: headers || {},
      responseType: responseType || "json",
    };

    if (method !== "get" && data) {
      config.data = data;
    }

    const request = await api(config);
    return request?.data;
  } catch (error) {
    const error_message = error.response?.data || "Something went wrong";
    throw error_message;
  }
};

export const apiDelete = (url, options) => apiRequest("delete", url, options);
export const apiGet = (url, options) => apiRequest("get", url, options);
export const apiPatch = (url, options) => apiRequest("patch", url, options);
export const apiPost = (url, options) => apiRequest("post", url, options);
export const apiPut = (url, options) => apiRequest("put", url, options);
