import { lazy } from "react";
import { Navigate } from "react-router-dom";

import LayoutSuspense from "./shared/LayoutSuspense";
import ModuleSuspense from "./shared/ModuleSuspense";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
const AuthLayout = lazy(() => import("./layouts/Auth"));
const DashboardLayout = lazy(() => import("./layouts/Dashboard"));

// Auth components
const SignIn = lazy(() => import("./pages/auth"));

// Dashboard components
const Dashboard = lazy(() => import("./pages/modules/dashboard"));

// notifications
const Notifications = lazy(() => import("./pages/modules/notifications"));
const SingleNotification = lazy(() =>
  import("./pages/modules/notifications/single-notification")
);

// Reports
const Reports = lazy(() => import("./pages/modules/reports"));
const EmployeeReports = lazy(() =>
  import("./pages/modules/reports/pages/employee-reports")
);
const HistoryReports = lazy(() =>
  import("./pages/modules/reports/pages/leave-reports")
);
const ContractorReports = lazy(() =>
  import("./pages/modules/reports/pages/contractor-reports")
);
const ConfirmationReports = lazy(() =>
  import("./pages/modules/reports/pages/confirmation-reports")
);
const TerminationTrend = lazy(() =>
  import("./pages/modules/reports/pages/termination-trend")
);
const Report = lazy(() => import("./pages/modules/reports/pages/report"));
const Metrics = lazy(() => import("./pages/modules/reports/pages/metrics"));

// Profile
const MyProfile = lazy(() => import("./pages/modules/profile/my-profile"));
const FeedbackModule = lazy(() => import("./pages/modules/profile/feedback"));
const Suggestions = lazy(() => import("./pages/modules/profile/suggestion"));
const LeaveModule = lazy(() => import("./pages/modules/profile/leave"));

// Manager
const Incident = lazy(() => import("./pages/modules/manager/incidents"));
const LeavePlans = lazy(() => import("./pages/modules/manager/leave-plans"));

// Settings
const Settings = lazy(() => import("./pages/modules/settings"));
const LeaveForm = lazy(() =>
  import("./pages/modules/settings/components/shared/LeaveTypeForm")
);

// Company components
const AnnouncementModule = lazy(() =>
  import("./pages/modules/team/announcement")
);

const TeamLeaveModule = lazy(() => import("./pages/modules/team/leave"));

const AnnouncementView = lazy(() =>
  import("./pages/modules/team/announcement/view")
);
const AnnouncementCreation = lazy(() =>
  import("./pages/modules/team/announcement/create/index")
);
const CompanyDocuments = lazy(() => import("./pages/modules/team/documents"));
const Employees = lazy(() => import("./pages/modules/team/employees"));
const EmployeeDetails = lazy(() =>
  import("./pages/modules/team/employees/Profile")
);

const Incidents = lazy(() => import("./pages/modules/team/incident"));
const Onboarding = lazy(() =>
  import("./pages/modules/team/employees/Profile/components/onboarding")
);
const Outboarding = lazy(() =>
  import("./pages/modules/team/termination/outboarding")
);
const Positions = lazy(() => import("./pages/modules/team/position"));
const Terminations = lazy(() => import("./pages/modules/team/termination"));
const TerminationView = lazy(() =>
  import("./pages/modules/team/termination/view")
);
const Unit = lazy(() => import("./pages/modules/team/unit"));

export const authenticated_routes = [
  {
    path: "/",
    element: (
      <LayoutSuspense>
        <DashboardLayout />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "/dashboard",
        element: (
          <ModuleSuspense>
            <Dashboard />
          </ModuleSuspense>
        ),
      },

      {
        path: "/profile/feedback",
        element: (
          <ModuleSuspense>
            <FeedbackModule />
          </ModuleSuspense>
        ),
      },
      {
        path: "/profile/suggestions",
        element: (
          <ModuleSuspense>
            <Suggestions />
          </ModuleSuspense>
        ),
      },
      {
        path: "/profile/leave",
        element: (
          <ModuleSuspense>
            <LeaveModule />
          </ModuleSuspense>
        ),
      },
      {
        path: "/settings/leaves/leave-types",
        element: (
          <ModuleSuspense>
            <LeaveForm />
          </ModuleSuspense>
        ),
      },
      {
        path: "/profile/my-profile",
        element: (
          <ModuleSuspense>
            <MyProfile />
          </ModuleSuspense>
        ),
      },
      {
        path: "/settings",
        element: (
          <ModuleSuspense>
            <Settings />
          </ModuleSuspense>
        ),
      },
      {
        path: "/outboarding",
        element: (
          <ModuleSuspense>
            <Outboarding />
          </ModuleSuspense>
        ),
      },
      {
        path: "/report/:id",
        element: (
          <ModuleSuspense>
            <Report />
          </ModuleSuspense>
        ),
      },
      {
        path: "/metrics/:id",
        element: (
          <ModuleSuspense>
            <Metrics />
          </ModuleSuspense>
        ),
      },

      { path: "/", element: <Navigate to="/dashboard" /> },
      {
        path: "*",
        element: <Navigate to="/dashboard" />,
      },
    ],
  },
  {
    path: "/team",
    element: (
      <LayoutSuspense>
        <DashboardLayout />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "employees",
        element: (
          <ModuleSuspense>
            <Employees />
          </ModuleSuspense>
        ),
      },
      {
        path: "/team/employees/:id",
        element: (
          <ModuleSuspense>
            <EmployeeDetails />
          </ModuleSuspense>
        ),
      },

      {
        path: "/team/employees/onboarding",
        element: (
          <ModuleSuspense>
            <Onboarding />
          </ModuleSuspense>
        ),
      },
      {
        path: "terminations",
        element: (
          <ModuleSuspense>
            <Terminations />
          </ModuleSuspense>
        ),
      },
      {
        path: "/team/terminations/:id",
        element: (
          <ModuleSuspense>
            <TerminationView />
          </ModuleSuspense>
        ),
      },
      {
        path: "incidents",
        element: (
          <ModuleSuspense>
            <Incidents />
          </ModuleSuspense>
        ),
      },
      {
        path: "positions",
        element: (
          <ModuleSuspense>
            <Positions />
          </ModuleSuspense>
        ),
      },
      {
        path: "units",
        element: (
          <ModuleSuspense>
            <Unit />
          </ModuleSuspense>
        ),
      },
      {
        path: "documents",
        element: (
          <ModuleSuspense>
            <CompanyDocuments />
          </ModuleSuspense>
        ),
      },
      {
        path: "announcements",
        element: (
          <ModuleSuspense>
            <AnnouncementModule />
          </ModuleSuspense>
        ),
      },
      {
        path: "/team/announcements/:id",
        element: (
          <ModuleSuspense>
            <AnnouncementView />
          </ModuleSuspense>
        ),
      },
      {
        path: "/team/announcements/:id/edit",
        element: (
          <ModuleSuspense>
            <AnnouncementCreation />
          </ModuleSuspense>
        ),
      },
      {
        path: "/team/announcements/create",
        element: (
          <ModuleSuspense>
            <AnnouncementCreation />
          </ModuleSuspense>
        ),
      },
      {
        path: "/team/leave",
        element: (
          <ModuleSuspense>
            <TeamLeaveModule />
          </ModuleSuspense>
        ),
      },
    ],
  },
  {
    path: "/notifications",
    element: (
      <LayoutSuspense>
        <DashboardLayout>
          <Notifications />
        </DashboardLayout>
      </LayoutSuspense>
    ),
    children: [
      {
        path: ":id",
        element: (
          <ModuleSuspense>
            <SingleNotification />
          </ModuleSuspense>
        ),
      },
    ],
  },
  {
    path: "/reports",
    element: (
      <LayoutSuspense>
        <DashboardLayout />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "/reports",
        element: (
          <ModuleSuspense>
            <Reports />
          </ModuleSuspense>
        ),
      },
      {
        path: "/reports/employee-reports",
        element: (
          <ModuleSuspense>
            <EmployeeReports />
          </ModuleSuspense>
        ),
      },
      {
        path: "/reports/contractor-reports",
        element: (
          <ModuleSuspense>
            <ContractorReports />
          </ModuleSuspense>
        ),
      },
      {
        path: "/reports/confirmation-reports",
        element: (
          <ModuleSuspense>
            <ConfirmationReports />
          </ModuleSuspense>
        ),
      },
      {
        path: "/reports/termination-trend",
        element: (
          <ModuleSuspense>
            <TerminationTrend />
          </ModuleSuspense>
        ),
      },
      {
        path: "/reports/leave-history",
        element: (
          <ModuleSuspense>
            <HistoryReports />
          </ModuleSuspense>
        ),
      },
    ],
  },
  {
    path: "/manager",
    element: (
      <LayoutSuspense>
        <DashboardLayout />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "incidents",
        element: (
          <ModuleSuspense>
            <Incident />
          </ModuleSuspense>
        ),
      },
      {
        path: "leave-plans",
        element: (
          <ModuleSuspense>
            <LeavePlans />
          </ModuleSuspense>
        ),
      },
    ],
  },
];

export const unauthenticated_routes = [
  {
    path: "/",
    element: (
      <LayoutSuspense>
        <AuthLayout />
      </LayoutSuspense>
    ),
    children: [
      {
        path: "/sign-in",
        element: (
          <ModuleSuspense>
            <SignIn />
          </ModuleSuspense>
        ),
      },
      {
        path: "/",
        element: <Navigate to="/sign-in" />,
      },
      {
        path: "*",
        element: <Navigate to="/sign-in" />,
      },
    ],
  },
];
