import { QueryOptions, useQuery } from "@tanstack/react-query";
import { toast } from "sonner";

interface ResourceOptionsProps
  extends Omit<QueryOptions, "queryKey" | "queryFn"> {
  key: string[];
  fn: () => Promise<any>;
  select?: (data: any) => any;
}

const useFetchResource = (options: ResourceOptionsProps) => {
  let { key, fn, select, ...rest } = options;

  return useQuery({
    ...rest,
    queryKey: key || ["defaultKey"],
    queryFn: async () => {
      const response = await fn();
      if (response?.error) {
        toast.error("Something went wrong");
        return;
      }

      return response;
    },
    select: select ? (data) => select!(data) : undefined,
  });
};

export default useFetchResource;
